module.exports = {
	"monospaceFontFamily": "'Consolas', 'Inconsolata', 'Droid Sans Mono',\n    'Monaco', monospace",
	"baseFontFamily": "'helvetica neue', helvetica, arial, sans-serif",
	"baseFontSize": 14,
	"spacingUnit": 5,
	"panelItemsSpacing": 8,
	"panelSpacing": 15,
	"iconsSize": 24,
	"primaryColor": "#00bcd4",
	"backgroundColor": "#fff",
	"darkBgColor": "#0b2840",
	"highlightColor": "#00bcd4",
	"secondaryColor": "#548f9e",
	"linkColor": "#42a0dd",
	"linkHoverColor": "#0262a0",
	"fieldNameColor": "#224d6f",
	"builtinColor": "#711c1c",
	"textColor": "#666",
	"shadowColor": "rgba(0, 0, 0, .1)",
	"alertColor": "#b71c1c",
	"modalBgColor": "#0b2840",
	"docPanelWidth": 320,
	"typeInfoPopoverWidth": 320,
	"docPanelBgColor": "#fff",
	"docPanelItemStripeColor": "rgba(158, 158, 158, .07)",
	"docPanelItemHoverColor": "rgba(214, 236, 238, .6)",
	"argDefaultColor": "#0b7fc7",
	"argNameColor": "#c77f53",
	"nodeFillColor": "#f6f8f8",
	"nodeHeaderColor": "#548f9e",
	"nodeHeaderTextColor": "white",
	"edgeColor": "rgb(56, 97, 107)",
	"selectedEdgeColor": "red",
	"selectedFieldBg": "rgba(255, 0, 0, .18)",
	"smallViewport": "(max-width: 900px)",
	"bigViewport": "(min-width: 901px)"
}